.menu-ani-button{
	cursor: pointer;
	position: relative;
	width:30px;/*---線の長さ---*/
	height:25px;/*---線の太さ長さによって調整---*/
}
.bar,.bar:before,.bar:after{
	height: 3px;/*---線の太さ---*/
	border-radius:3px;
	width: 100%;
	background-color: #3fa9f5;
	transition: all .25s;
	position: absolute;
}
.bar {
	left: 0;
	top: 10px;
}
.bar:before {
	content: "";
	left: 0;
	top: -10px;
}
.bar:after {
	content: "";
	left: 0;
	top: 10px;
}

.menu-animated .bar {
	background-color: transparent;
	transition: all .25s;
}
.menu-animated .bar:before {
	transition: all .25s;
	content: "";
	transform: rotate(45deg);
	top: 0px;
}
.menu-animated .bar:after {
	transition: all .25s;
	content: "";
	transform: rotate(-45deg);
	top: 0px;
}
